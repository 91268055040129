<template>
  <div v-if="user" class="user">
    <div class="container">
      <div class="user__row">
        <div class="user__col user__col--big">
          <router-link :to="{ name: 'Users' }" class="user__back">
            <img src="../assets/icons/icon-arrow-left.svg" alt="">
            Назад
          </router-link>
          <div class="user__name">
            Пользователь {{ user.name }}
          </div>
        </div>
        <div class="user__short-data user__col user__col--small">
          <div class="user__short-data-item">
            <div v-if="user.last_product" class="user__short-data-title">Тариф:</div>
            <div v-if="user.last_product" class="user__short-data-value"> 
              {{ user.last_product.product.name }} <span>До {{ fixedDate(user.role_expiration_date) }}</span>
            </div>
          </div>
          <div class="user__short-data-item">
            <div class="user__short-data-title">Проектов:</div>
            <div class="user__short-data-value">{{ user.projects_count }}</div>
          </div>
        </div>
      </div>
      <div class="user__content user__row">
        <div class="user__col user__col--big">
          <div class="user__data">
            <div class="user__data-item">
              <div class="user__data-title">
                Имя
              </div>
              <div class="user__data-value">
                {{ user.name }}
              </div>
            </div>
            <div class="user__data-item">
              <div class="user__data-title">
                Фамилия
              </div>
              <div class="user__data-value">
                {{ user.surname }}
              </div>
            </div>
            <div class="user__data-item">
              <div class="user__data-title">
                Email
              </div>
              <div class="user__data-value">
                {{ user.email }}
              </div>
            </div>
            <div class="user__data-item">
              <div class="user__data-title">
                Организация
              </div>
              <div class="user__data-value">
                {{ user.company }}
              </div>
            </div>
            <div class="user__data-item">
              <div class="user__data-title">
                Должность
              </div>
              <div class="user__data-value">
                {{ user.position }}
              </div>
            </div>
            <div class="user__data-item">
              <div class="user__data-title">
                Роль
              </div>
              <div class="user__data-value">
                {{ role(user.role) }}
              </div>
            </div>
          </div>
        </div>
        <div class="user__col user__col--small">
          <div class="user__actions">
            <!-- <div class="user__action">Изменить роль</div> -->
            <!-- <div class="user__action">Предоставить доступ к тарифу</div> -->
            <div class="user__action" @click="updateUser">Редактировать данные</div>
            <el-popconfirm
              confirm-button-text="OK"
              cancel-button-text="Отмена"
              :icon="InfoFilled"
              icon-color="red"
              title="Удалить пользователя ?"
              @confirm="delUser"
            >
              <template #reference>
                <div class="user__action">Удалить</div>
              </template>
            </el-popconfirm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { DateParser } from '@/mixin/date_parser'

export default {
  name: 'User',
  mixins: [DateParser],
  data() {
    return {}
  },
  created() {
    this.getUser(this.$route.params.id)
  },
  computed: {
    user(){
      return this.$store.state.Users.user
    },
  },
  methods: {
    ...mapActions('Users', [
        'getUser'
    ]),
    updateUser(){
      this.$store.commit('Dialog/setData', { 
        label: 'activeDialog', 
        data: 'User'
      })
      this.$store.commit('Dialog/setData', { 
        label: 'titleDialog', 
        data: 'Изменить пользователя'
      })
      this.$store.commit('Dialog/setData', { 
        label: 'dialogVisible', 
        data: true 
      })
    },
    delUser(){
      this.$store.dispatch('Users/delUser', this.$route.params.id)
        .then(() => {
          this.$router.push('/users')
        })
        .catch(err => {
          console.log(err.response.data.errors)
        })
    },
    role(role){
      let roles = {
        admin: 'Администратор',
        demo: 'Пользователь демо-доступа',
        teaching: 'Пользователь тарифа для учебных заведений',
        expert: 'Пользователь тарифа для организации',
      }
      return roles[role]
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.user {
  padding: 6rem 0;
  &__row {
    display: flex;
  }

  &__col {
    &--big {
      width: 60%;
    }

    &--small {
      width: 40%;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    margin-bottom: 1rem;
    color: $darkGrey;

    &>img {
      display: block;
      margin-right: 1rem;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.8rem;
  }

  &__short-data {
    display: flex;
    justify-content: space-between;

    &-title {
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 1rem;
    }

    &-value {
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.8rem;

      span {
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-weight: 400;
      }
    }
  }

  &__content {
    border-top: 2px solid $darkGrey;
    margin-top: 6rem;
    padding-top: 8rem;
  }

  &__data {
    &-item {
      margin-bottom: 5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-title {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $darkGrey;
    }

    &-value {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: $lightBlack;
    }
  }

  &__action {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $blue;
    cursor: pointer;
    margin-bottom: 2rem;

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      margin-top: 10rem;
      max-width: max-content;
    }
  }
}
</style>
